import React, { useState, useEffect } from "react"
import { getData } from "../../services/Ajax"
import { TopAddButton } from "../layout/Button"
import Placeholder from "../layout/Placeholder"
import { ProntoContainerTop } from "../layout/Containers"
import BackButton from "../backbutton/BackButton"
import DoorBlockListItem from "./DoorBlockItem"

const DoorBlocks = (props) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const url = "/api/doorblock"
      const response = await getData(url)
      const result = await response
      setData(result)
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>
      <TopAddButton
        className="flex-right btn-control btn--red"
        to={"/blockdoors/add/"}
        title={Placeholder.Create}
      />
      <BackButton disabled={true} {...props}>
        {Placeholder.BlockDoors.Title}
      </BackButton>
      <ul className="no-style drop-shadow">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">
              Skapad
            </div>
            <div className="list-item__element label hidden-down">
              Personnummer
            </div>
            <div className="list-item__element label hidden-down">
              ACR
            </div>
            <div className="list-item__element label hidden-down">
              ID
            </div>
            <div className="list-item__element label hidden-down">
              {Placeholder.BlockDoors.BlockedBy}
            </div>
            <div className="list-item__element label hidden-down">
              <div>{Placeholder.BlockDoors.BlockedByStore}</div>
            </div>
            <div className="list-item__element label hidden-down">
              {Placeholder.Delete}
            </div>
          </div>
        </li>
        {data.map((item) => (
          <li key={item.id}>
            <DoorBlockListItem data={item} {...props} />
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
}

export default DoorBlocks
