import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import InputField from '../Form/InputField'
import InputLabel from '../Form/InputLabel'
import { AngleIcon } from '../layout/Icons'
import styles from './Dropdown.module.css'

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string
  })).isRequired,
  setSelected: PropTypes.func,
  large: PropTypes.bool,
  beforeDropdown: PropTypes.func,
  afterDropdown: PropTypes.func,
  error: PropTypes.bool,
}

function Dropdown({ label, placeholder, listItems, setSelected, large, beforeDropdown, afterDropdown, error }) {
  const ref = useRef()
  const [open, setOpen] = React.useState(false)

  function onSelectItem(e, selected) {
    setOpen(false)
    setSelected(e, selected)
  }

  React.useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [open])

  return (
    <InputField >
      <InputLabel label={label} className={large ? styles.dropdownLabel : ''} />
      {beforeDropdown && beforeDropdown()}
      <div className={large ? styles.largeDropdown : styles.dropdown} ref={ref}>
        <button
          className={[open ? styles.openSelected : styles.selected, large ? styles.boldSelected : '', error ? styles.errorSelected : ''].join(' ')}
          onClick={(e) => {
            e.preventDefault()
            setOpen((prev) => !prev)
          }}
        >
          <span>{placeholder}</span>
          <span className={[open ? styles.openListIcon : styles.closedListIcon, large ? styles.red : ''].join(' ')}>
            <AngleIcon direction='down' className={styles.angleIcon} />
          </span>
        </button>

        {open ? (
          <ul className={styles.openList}>
            {listItems.map((item, index) => {
              return (
                <li key={item + index} className={styles.listItem}>
                  <button onClick={(e) => onSelectItem(e, item)}>{item.name}</button>
                </li>
              )
            })}
          </ul>
        ) : null}
      </div>
      {afterDropdown && afterDropdown()}
    </InputField>
  )
}

export default Dropdown
