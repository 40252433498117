import React from "react"
import InputField from "./InputField"
import InputLabel from "./InputLabel"
import ReactTimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css'
import "./TimePicker.css"

function TimePicker({ 
  label,
  name,
  value,
  locale = "sv",
  disableClock = true,
  onChange,
  error,
}) {
  return (
    <InputField>
      <InputLabel label={label} />
      <ReactTimePicker 
        name={name}
        value={value}
        locale={locale}
        disableClock={disableClock}
        onChange={onChange}
        className={error ? "timePickerInput timePickerError" : "timePickerInput"}
      />
    </InputField>
  )
}
  
export default TimePicker