import React from 'react'
import QRCode from 'react-qr-code'
import RadioButton from '../layout/RadioButton'
import './QRGenerator.css'
import { getData, getServiceData } from '../../services/HttpClient'

const QRType = {
  Catering: 'catering',
  Restaurant: 'restaurant',
  Table: 'bord',
}

function QRGenerator({ storeId }) {
  const [qrValue, setQrValue] = React.useState('')
  const [value, setValue] = React.useState('')
  const [error, setError] = React.useState(false)
  const [imgURI, setImgURI] = React.useState('')
  const [qrType, setQrType] = React.useState(QRType.Restaurant)
  const [cateringUrl, setCateringUrl] = React.useState(null)
  // const [isTableFormat, setIsTableFormat] = React.useState(false)
  const stageStore = '1214de17-a004-44f0-19ef-08d6538111e5'
  const backofficeDev = 'cd922853-0b00-44ab-2509-08d6877f6e25'

  React.useEffect(() => {
    const fetchCateringData = async (accountNumber) => {
      try {
        const response = await getServiceData(
          'FoodApi',
          'v1',
          `/catering/${accountNumber}`,
        )
        setCateringUrl(response?.data?.settings?.msoCateringStartPage || null)
      } catch (error) {
        console.error('Error fetching catering data:', error)
      }
    }

    const fetchStoreData = async () => {
      const res = await getData('/api/stores/' + storeId)
      if (res.ok && res.data && res.data.accountNumber) {
        fetchCateringData(res.data.accountNumber)
      }
    }

    if (storeId) {
      fetchStoreData()
    }
  }, [storeId])

  const baseUrl = React.useMemo(() => {
    return `https://kiosk-mobile.icapronto.se/butik/${
      storeId !== backofficeDev ? storeId : stageStore
    }`
  }, [storeId])

  async function onSubmit() {
    try {
      if (qrType === QRType.Catering) {
        setQrValue(cateringUrl)
      } else {
        setQrValue(
          qrType === QRType.Table && value.length > 0
            ? `${baseUrl}/?bord=${value}`
            : baseUrl,
        )
      }
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }

  React.useEffect(() => {
    if (error) {
      qrType !== QRType.Table && setError(false)
    }
  }, [qrType, error])

  function onInputFocus() {
    setQrType(QRType.Table)
    if (error) {
      setError(false)
    }
  }

  React.useEffect(() => {
    function createImg() {
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      const svg = document.getElementById('QrCode')
      const data = new XMLSerializer().serializeToString(svg)
      const DOMURL = window.URL || window.webkitURL || window

      const img = new Image()
      const svgBlob = new Blob([data], {
        type: 'image/svg+xml;charset=utf-8',
      })
      const url = DOMURL.createObjectURL(svgBlob)

      img.onload = function () {
        canvas.width = this.naturalWidth
        canvas.height = this.naturalHeight

        ctx.drawImage(img, 0, 0)
        DOMURL.revokeObjectURL(url)

        var imgURI = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')

        setImgURI(imgURI)
      }

      img.src = url
    }
    qrValue.length > 0 && createImg()
  }, [qrValue])

  return (
    <div className="qrGeneratorWrapper">
      <div className="content">
        <div className="qrContainer">
          {qrValue && (
            <div>
              <p className="tooltip">{qrValue}</p>
              <QRCode id={'QrCode'} value={qrValue} size={270} />
            </div>
          )}
        </div>
        <div className={'inputContainer'}>
          {Object.values(QRType)
            .filter((type) => type !== 'catering' || cateringUrl !== null)
            .map((type) => {
              return (
                <RadioButton
                  key={type}
                  label={type.charAt(0).toUpperCase() + type.slice(1)}
                  name={type}
                  id={type}
                  onChange={() => setQrType(type)}
                  checked={qrType === type}
                />
              )
            })}
          <input
            className={error ? 'errorInput' : 'input'}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={onInputFocus}
            type="text"
            placeholder="Ange bordsnummer"
          />
        </div>
        <button className="button" type="button" onClick={onSubmit}>
          Generera QR-kod
        </button>
        {qrValue.length > 0 && (
          <button className="copyButton" type="button">
            <a
              target="_blank"
              rel="noreferrer"
              href={imgURI}
              download={`QR-kod-${qrType}${
                qrType === QRType.Table ? `-${value}` : ''
              }`}
            >
              Ladda ner bild
            </a>
          </button>
        )}
        <canvas id="canvas"></canvas>
      </div>
    </div>
  )
}

export default QRGenerator
