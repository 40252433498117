import React, { useState, useEffect } from 'react'
import { getData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainerTop } from '../layout/Containers'
import GoogleMapReact from 'google-map-react'
import { LinkButton } from '../layout/Button'
import ArrowListItem from '../layout/ArrowListItem'
import {
  ThemeIcon,
  LagaIcon,
  CalenderIcon,
  CartPlusIcon,
  BagIcon,
  CarrotIcon,
  ContactIcon,
  PlusIcon,
  PenIcon,
  QrIcon,
  CreditCardIcon,
} from '../layout/Icons'
import { GetGoogleAPiKey } from '../../utilis/Enviroment'
import '../layout/BulletinBoard.css'
import '../layout/StatsContainer.css'

// this should be composed in backend

const StoreNavigation = ({ store, roles }) => {
  var items = [
    { url: `/stores/${store.id}/orders/`, name: 'Ordrar', icon: <LagaIcon /> },
    {
      url: `/stores/${store.id}/reports/`,
      name: 'Rapporter',
      icon: <LagaIcon />,
    },
  ]
  var showCatering = store.channelTypes.find(
    (c) => c.displayName === 'ICA Catering' && c.checked,
  )
  if (showCatering) {
    items = items.concat([
      {
        url: `/stores/${store.id}/catering/`,
        name: 'Catering',
        icon: <PenIcon />,
      },
    ])
  }
  if (!roles.isReader) {
    items = items.concat([
      {
        url: `/stores/${store.id}/restaurants/`,
        name: 'Restauranger',
        icon: <CarrotIcon />,
      },
      {
        url: `/stores/${store.id}/categories/`,
        name: 'Kategorier',
        icon: <BagIcon />,
      },
      {
        url: `/stores/${store.id}/themes/`,
        name: 'Teman',
        icon: <ThemeIcon />,
      },
      {
        url: `/stores/${store.id}/recipes/`,
        name: 'Laga själv',
        icon: <LagaIcon />,
      },
      {
        url: `/stores/${store.id}/openhours/`,
        name: 'Öppettider',
        icon: <CalenderIcon />,
      },
      {
        url: `/stores/${store.id}/deliveryproviders/`,
        name: 'Leverans',
        icon: <CartPlusIcon />,
      },
      {
        url: `/stores/${store.id}/paymentMethods`,
        name: Placeholder.PaymentMethod,
        icon: <CreditCardIcon color="currentColor" />,
      },
      {
        url: `/stores/${store.id}/campaigns/`,
        name: 'Kampanjer',
        icon: <PlusIcon />,
      },
      {
        url: `/stores/${store.id}/swishpayments/`,
        name: 'Swishbetalningar',
        icon: <LagaIcon />,
      },
      {
        url: `/stores/${store.id}/imports/`,
        name: 'Produktuppdateringar',
        icon: <PlusIcon />,
      },
      {
        url: `/stores/${store.id}/messages/`,
        name: 'Meddelanden',
        icon: <PenIcon />,
      },
      {
        url: `/stores/${store.id}/qr-codes/`,
        name: 'Hantera Qr-koder',
        icon: <QrIcon />,
      },
    ])
  }

  if (roles.isAdmin || (!roles.isReader && roles.isStoreUser)) {
    items.push({
      url: `/stores/${store.id}/unmanned/`,
      name: 'Obemannat',
      icon: <PlusIcon />,
    })
  }

  if (roles.isAdmin) {
    items = items.concat([
      {
        url: `/stores/${store.id}/resend`,
        name: 'Återrapporteringar',
        icon: <LagaIcon />,
      },
    ])
  }

  const menuItems = items

  return (
    <div className="mid-container arrow-list drop-shadow">
      <div className="arrow-list__header">
        <h3>Tjänster</h3>
      </div>
      <ul className="no-style">
        {menuItems.map((item, index) => (
          <ArrowListItem
            key={index}
            url={item.url}
            icon={item.icon}
            name={item.name}
          />
        ))}
      </ul>
    </div>
  )
}
const StoreItem = (props) => {
  const storeid = props.storeid ? props.storeid : props.match.params.storeid
  const [store, setStore] = useState({})
  const [statsItems, setStatsItems] = useState([
    {
      name: 'Orders',
      numbersForThisWeek: 0.0,
      numbersForLastWeek: 0.0,
      numbersForToday: 0.0,
      type: 'number',
    },
    {
      name: 'Måltider',
      numbersForThisWeek: 0.0,
      numbersForLastWeek: 0.0,
      numbersForToday: 0.0,
      type: 'number',
    },
    {
      name: 'Varor',
      numbersForThisWeek: 0.0,
      numbersForLastWeek: 0.0,
      numbersForToday: 0.0,
      type: 'number',
    },
    {
      name: 'Försäljning (kr)',
      numbersForThisWeek: 0.0,
      numbersForLastWeek: 0.0,
      numbersForToday: 0.0,
      type: 'currency',
    },
  ])
  const [latLong, setLatLong] = useState()
  const [showNavigation, setShowNavigation] = useState(false)
  const [roles, setRoles] = useState({ isAdmin: false, isReader: true })

  useEffect(() => {
    if (storeid) {
      const fetchData = async () => {
        //Store Data
        const response = await getData('/api/stores/' + storeid)
        const result = await response
        setStore(result)

        const readOnlyResponse = await getData('api/navigation/roles')
        const roles = await readOnlyResponse
        setRoles(roles)
        setShowNavigation(true)

        //Status report
        const statsResponse = await getData('/api/stats/' + storeid)

        setStatsItems(statsResponse)
        setLatLong({ lat: result.latitude, lng: result.longitude })
      }

      fetchData()
    }
  }, [storeid])
  const zoom = 15

  const renderMarkers = (map, maps) => {
    new maps.Marker({
      position: latLong,
      map,
      title: store.name,
    })
  }

  return (
    <ProntoContainerTop>
      <h1 className="main-headline">{store.name}</h1>

      {store.description && <p className="main-byline">{store.description}</p>}

      {statsItems && statsItems.length > 0 && (
        <div className="stats-container-wrapper drop-shadow">
          <div className="stats-container-wrapper__header">
            <h3>{Placeholder.Reports.Reports}</h3>
          </div>
          <div className="stats-container-wrapper__body">
            <div className="stats-container">
              {statsItems.map((item, index) => (
                <div key={index} className="stats-container__item">
                  <h4 className="stats-container__headline">{item.name}</h4>
                  {item.type === 'number' && (
                    <span className="stats-container__today_number">
                      {item.numbersForToday}{' '}
                    </span>
                  )}
                  {item.type === 'number' && (
                    <span className="stats-container__today_number_row">
                      <span
                        title="Last week"
                        className="stats-container__lastweek_Number"
                      >
                        {item.numbersForLastWeek}
                      </span>
                      <span
                        title="This week"
                        className="stats-container__currentweek_number"
                      >
                        /{item.numbersForThisWeek}{' '}
                      </span>{' '}
                    </span>
                  )}
                  {item.type === 'currency' && (
                    <span className="stats-container__today_number">
                      {item.numbersForToday.toFixed(2)}
                    </span>
                  )}
                  {item.type === 'currency' && (
                    <span className="stats-container__today_number_row">
                      {' '}
                      <span
                        title="Last week"
                        className="stats-container__lastweek_Number"
                      >
                        {item.numbersForLastWeek.toFixed(2)}
                      </span>
                      <span
                        title="This week"
                        className="stats-container__currentweek_number"
                      >
                        /{item.numbersForThisWeek.toFixed(2)}
                      </span>
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="bulletin-board__row-header bulletin-board__row-header-spacing">
          <h3>Adress & namn</h3>
        </div>
        <div className="bulletin-board drop-shadow">
          <div className="bulletin-board__column">
            {store && (
              <div className="bulletin-board__row">
                <p>
                  {store.name}
                  <br />
                  {store.streetAddress}
                  <br />
                  {store.postalcode + ' ' + store.city}
                  <br />
                </p>
              </div>
            )}
            <div className="bulletin-board__row">
              <div className="bulletin-board__row-header">
                <h3>
                  <span className="icon--red mr-10">
                    <ContactIcon />
                  </span>{' '}
                  Kontaktinformation
                </h3>
              </div>
              <p>
                {store.contactName}
                <br />
                <a href={'mailto:' + store.contactEmail}>
                  {store.contactEmail}
                </a>
                <br />
                {store.contactPhoneNumber}
                <br />
              </p>
              <LinkButton
                className="btn--white btn--inline-flex mt-10 mb-10-mobile"
                to={`/stores/${store.id}/edit/`}
                title={Placeholder.Change}
              />
            </div>
          </div>
          <div className="bulletin-board__column">
            <div className="bulletin-board__row">
              <div className="bulletin-board__placeholder">
                {latLong && (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: GetGoogleAPiKey(),
                    }}
                    defaultCenter={latLong}
                    defaultZoom={zoom}
                    onGoogleApiLoaded={({ map, maps }) =>
                      renderMarkers(map, maps)
                    }
                  ></GoogleMapReact>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showNavigation && (
        <StoreNavigation store={store} roles={roles}></StoreNavigation>
      )}
    </ProntoContainerTop>
  )
}

export default StoreItem
