import React, { useState, useEffect } from "react"
import Placeholder from '../layout/Placeholder'
import SelectList from '../Form/SelectList'
import Textbox from '../Form/Textbox'
import { defaultNotifications } from "../../utilis/Notifications"
import FormActions from "../Form/FormActions"
import ContentContainer from '../layout/ContentContainer'
import { getServiceData, sendServiceData } from "../../services/HttpClient"

const UnmannedSettings = (props) => {
  const history = props.history
  const storeId = props.match.params.storeid
  const storeTypes = [
    { id: 'Store', name: Placeholder.UnmannedSettings.StoreType.Store },
    { id: 'BranchStore', name: Placeholder.UnmannedSettings.StoreType.BranchStore },
    { id: 'VendingMachine', name: Placeholder.UnmannedSettings.StoreType.VendingMachine },
  ]

  const [saving, setSaving] = useState(false)
  const [unmannedSettings, setUnmannedSettings] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        const response = await getServiceData("FoodApi", "V1", `/UnmannedSettings/${storeId}`)
        if (response.ok && response.data) {
          setUnmannedSettings(response.data)
        } else {
          setUnmannedSettings({ storeId: storeId, mainStoreName: '', storeType: 1, cashierId: '' })
        }
      }
    }

    fetchData()
  }, [storeId])

  const handleValueChange = (target, value) => {
    let newData = { ...unmannedSettings }
    newData[target] = value !== "" ? value : null
    setUnmannedSettings(newData)
  }

  const handlePostData = async (e) => {
    e.preventDefault()
    setSaving(true)
    const response = await sendServiceData("FoodApi", "V1", "/UnmannedSettings", unmannedSettings, "POST")
    setSaving(false)
    if (response.ok) {
      defaultNotifications.updated(Placeholder.UnmannedSettings.Title)
      navigateBack()
    } else {
      defaultNotifications.error(Placeholder.UnmannedSettings.Title)
    }
  }

  function navigateBack() {
    history.push(window.location.pathname + '../unmanned/')
  }

  return (
    <ContentContainer
      title={Placeholder.UnmannedSettings.Title}
      fetchingData={!unmannedSettings}
      backTo="../unmanned"
    >
      <form className="form" id="unmannedSettingsForm" onSubmit={handlePostData}>
        <SelectList
          label={Placeholder.UnmannedSettings.StoreType.Title}
          options={storeTypes}
          name="storeType"
          value={unmannedSettings?.storeType ?? undefined}
          onChange={(e) => handleValueChange(e.target.name, e.target.value)}
        />
        <Textbox
          label={Placeholder.UnmannedSettings.MainStoreName}
          name="mainStoreName"
          value={unmannedSettings.mainStoreName ?? undefined}
          onChange={(e) => handleValueChange(e.target.name, e.target.value)}
        />
        {unmannedSettings.cashierId > 0 && unmannedSettings.storeType == 'VendingMachine' && <Textbox
          label={Placeholder.UnmannedSettings.cashierId}
          name="cashierId" readonly
          value={unmannedSettings.cashierId ?? undefined}
        />}
        <FormActions
          form="unmannedSettingsForm"
          onCancel={navigateBack}
          saving={saving}
        />
      </form>
    </ContentContainer>
  )
}

export default UnmannedSettings
