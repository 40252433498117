import React, { useState, useEffect } from 'react'
import {TopAddButton} from '../layout/Button'
import { getData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import ItemList, { ItemListColumn } from '../ItemList/ItemList'
import { Link } from 'react-router-dom'
import Moment from "moment"
import LoadingContainer from '../layout/LoadingContainer'


const SystemMessages = (props)=> {
  const [data, setData] = useState([])
  const [applications, setApplications] = useState([])
  const [fetching, setFetching] = useState(false)

  useEffect(() => {

    async function fetchData() {
      setFetching(true)
      try {
        const res = await getData('/api/system/messages')

        if(res.ok) {
          setData(res.data)
        }

        const applicationResult = await getData('api/system/applications')

        if(applicationResult.ok) {
          setApplications(applicationResult.data)
        }
      } catch (error) {
        console.log(error)
      }
      setFetching(false)
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop >
      <TopAddButton className="flex-right btn-control btn--red" to={'/system/messages/create/'} title={`${Placeholder.Create} systemmeddelande`} />
      <BackButton {...props} to={`/stores`}>Systemmeddelanden</BackButton>
      <LoadingContainer loading={fetching}>
        <ItemList
          data={data}
          showEmpty={false}
          shadow={false}
          stickyHeader={true}
          idProp={"id"}
          renderRow={(item) =>
            <React.Fragment>
              <ItemListColumn id="application" label="Applikation">
                {applications.find(app => app.id == item.application)?.name}
              </ItemListColumn>
              <ItemListColumn id="date" label={Placeholder.Date}>
                {Moment (item.date).format('YYYY-MM-DD')}
              </ItemListColumn>
              <ItemListColumn id="startTime" label={Placeholder.From}>
                {item.startTime?.slice(0, -3)}
              </ItemListColumn>
              <ItemListColumn id="endTime" label={Placeholder.To}>
                {item.endTime?.slice(0, -3)}
              </ItemListColumn>
              <ItemListColumn id="edit">
                <Link className="link-edit" to={`/system/messages/${item.id}/edit/`}>{Placeholder.Edit}</Link>
              </ItemListColumn>
            </React.Fragment>
          }>
        </ItemList>
      </LoadingContainer>
    </ProntoContainerTop>
  )
  
}

export default SystemMessages