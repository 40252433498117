/* eslint-disable indent */
import React, { useState, useEffect } from "react"
import { getData, postData } from "../../services/Ajax"
import Placeholder from "../layout/Placeholder"
import Mode from "../../utilis/Mode"
import { ProntoContainer } from "../layout/Containers"
import BackButton from "../backbutton/BackButton"
import { ClickButton, SaveButton } from "../layout/Button"
import { getEnvirionment } from '../../utilis/GetEnvironment'
import { getServiceData } from '../../services/HttpClient'
import SelectList from '../Form/SelectList'
import CheckboxV2 from '../layout/CheckboxV2'
import styles from './DoorBlock.module.css'

const DoorBlock = (props) => {
  const history = props.history
  const mode = props.mode
  const doorBlockId = props.match.params.doorBlockId

  const [data, setData] = useState({ id: doorBlockId })
  const [stores, setStores] = useState([])
  const [validSsn, setValidSsn] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (doorBlockId) {
        const url = `/api/doorblock/${doorBlockId}`
        const response = await getData(url)
        const result = await response
        setData(result)
      }
      else {
        const env = getEnvirionment(window.location.host)
        const acr = env === 'production' ? "icase-bankid-qr bankid" : "icase-bankid-qr bankid test"
        let newData = data
        newData.acr = acr
        setData(newData)
      }
      
      const storesResponse = await getServiceData('FoodApi', 'v1', `/store`)
      setStores(storesResponse.data.filter(s => s.active))
    }

    fetchData()
  }, [])

  const handleSsnChange = (event) => {
    event.persist()
    const value = event.target.value
    if (/^\d{8}\d{4}$/.test(value)) {
      setValidSsn(true)
      data[event.target.name] = value
      setData(data)
    } else {
      setValidSsn(false)
    }
  }

  const handleInputChange = (event) => {
    let value
    switch (event.target.type) {
      case 'checkbox':
        value = event.target.checked
        break
      default:
        value = event.target.value
        break
    }

    const newData = { ...data }
    newData[event.target.name] = value
    setData(newData)
  }

  const handlePostData = async () => {
    const url = getPostUrl()
    const response = await postData(url, data)
    history.goBack()
    return await response
  }

  const getPostUrl = () => {
    if (mode === Mode.Create) {
      return "/api/doorblock/create"
    }
    if (mode === Mode.Delete) {
      return "/api/doorblock/delete"
    }
  }

  return (
    <div>
      <BackButton editPage={true} backLabel={Placeholder.BlockDoors.Title} {...props}>
        {Placeholder.BlockDoors.Title}
      </BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            <label htmlFor="ssn">{Placeholder.BlockDoors.SsnLabel}</label>
            <input
              className="input"
              disabled={doorBlockId}
              type="text"
              id="ssn"
              name="ssn"
              onChange={handleSsnChange}
              defaultValue={data.ssn}
              placeholder="YYYYMMDDXXXX"
            />
            <label htmlFor="store">{Placeholder.BlockDoors.StoreLabel}</label>
            <SelectList
              id="createdByStoreId"
              name="createdByStoreId"
              options={stores}
              optionValueField="id"
              optionDisplayField="name"
              value={data.createdByStoreId}
              onChange={handleInputChange} />
            <div className={styles.checkboxSpacing}>
              <CheckboxV2
                id="customerHasNonpayment"
                name="customerHasNonpayment"
                label={Placeholder.BlockDoors.CustomerHasNonpaymentLabel}
                checked={!!data.customerHasNonpayment}
                onChange={handleInputChange}
              />
              <CheckboxV2
                id="customerHasMismanagedWeighedItems"
                name="customerHasMismanagedWeighedItems"
                label={Placeholder.BlockDoors.CustomerHasMismanagedWeighedItemsLabel}
                checked={!!data.customerHasMismanagedWeighedItems}
                onChange={handleInputChange}
              />
              <CheckboxV2
                id="customerHasLetOthersIntoStore"
                name="customerHasLetOthersIntoStore"
                label={Placeholder.BlockDoors.CustomerHasLetOthersIntoStoreLabel}
                checked={!!data.customerHasLetOthersIntoStore}
                onChange={handleInputChange}
              />
              <CheckboxV2
                id="customerHasVandalized"
                name="customerHasVandalized"
                label={Placeholder.BlockDoors.CustomerHasVandalizedLabel}
                checked={!!data.customerHasVandalized}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row">
            <SaveButton
              className="btn--black mr-10"
              disabled={Mode.Create === mode && !validSsn}
              title={
                Mode.Delete === mode
                  ? Placeholder.Delete
                  : Placeholder.SaveButton
              }
              onClick={handlePostData}
            />
            <ClickButton
              className="btn--white"
              title={Placeholder.Cancel}
              onClick={history.goBack}
            />
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
}

export default DoorBlock
