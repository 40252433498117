import React, { useState, useEffect } from 'react'
import Placeholder from '../layout/Placeholder'
import Mode from '../../utilis/Mode'
import Moment from "moment"
import Textbox from '../Form/Textbox'
import css from './SystemMessage.module.css'
import ContentContainer from '../layout/ContentContainer'
import FormActions from '../Form/FormActions'
import Dropdown from '../layout/Dropdown'
import DatePicker from '../Form/DatePicker'
import TimePicker from '../Form/TimePicker'
import { getData, sendData } from '../../services/HttpClient'
import { defaultNotifications } from '../../utilis/Notifications'
import { useForm } from 'react-hook-form'
import LoadingContainer from '../layout/LoadingContainer'

const SystemMessage = (props)=> {
  const mode = props.mode
  const id = props.match.params.id
  const history = props.history
  const [applications, setApplications] = useState( [] )
  const initalData = {
    date: Moment(new Date()).format('YYYY-MM-DD'),
    application: null,
    startTime: "",
    endTime: "",
    title: "",
    message: ""
  }
  const [data, setData] = useState(initalData)
  const navigateToSystemMessages = `/system/messages/`
  const [saving, setSaving] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  const [validationErrors, setValidationErrors] = React.useState({application: '', date: '', startTime: '', endTime: '', timeInput: ''})

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    let componentMounted = true

    async function fetchApplications() {
      try {
        const res = await getData('/api/system/applications')

        if(componentMounted && res.ok) {
          const applicationResponse = res.data.map((item) => ({
            ...item,
            id: (item.id).toString(),
            value: (item.value).toString(),
          }))
          setApplications(applicationResponse)
          if(!id) {
            reset(initalData)
          }
        }

      } catch (error) {
        console.log(error)
      }
      
    }

    fetchApplications()
    return () => {
      componentMounted = false
    }
  }, [])

  useEffect(() => {
    let componentMounted = true

    async function fetchData() {
      setFetching(true)
      try {
        const res = await getData(`api/system/messages/${id}`)

        if(componentMounted && res.ok) {
          setData(res.data)
          reset(res.data)
        }

      } catch (error) {
        console.log(error)
      }
      setFetching(false)
    }

    if(id) {
      fetchData()
    }

    return () => {
      componentMounted = false
    }
  }, [id])

  const getPostUrl = () => {
    if(mode === Mode.Edit){
      return '/api/system/messages/edit'
    }
    if(mode === Mode.Create){
      return '/api/system/messages/create'
    }

    return undefined
  }

  function validTimeInput(startTime, endTime) {
    if (startTime > endTime) {
      return false
    }
    return true
  }

  function validDateInput(date) {
    const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?$/
    if (new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      return false
    }
    if(!regex.test(date)) {
      return false
    }
    return true
  }

  function validateData(data) {
    let validData = true
    if(!data.application) {
      setValidationErrors(prev => ({...prev, application: 'Välj en tjänst'}))
      validData = false
    }
    if(!validDateInput(data.date)) {
      setValidationErrors(prev => ({...prev, date: 'Datumet måste vara på formatet YYYY-MM-DD och kan inte vara ett datum som passerat'}))
      validData = false
    }
    if(!data.startTime) {
      setValidationErrors(prev => ({...prev, startTime: 'Starttid saknas'}))
      validData = false
    }
    if(!data.endTime) {
      setValidationErrors(prev => ({...prev, endTime: 'Sluttid saknas'}))
      validData = false
    }
    if((data.startTime && data.endTime) && !validTimeInput(data.startTime, data.endTime)) {
      console.log(data.startTime > data.endTime)
      setValidationErrors(prev => ({...prev, timeInput: 'Sluttid måste vara efter starttid'}))
      validData = false
    }

    return validData
  }

  const onSubmit = async () => {
    if(!validateData(data)) {
      return
    }

    setSaving(true)

    try {
      const response = await sendData(getPostUrl(), data, "POST")
      if (response.ok) {
        if(mode === Mode.Create) {
          const notificationMessage = `${findName(applications, data.application)}: ${Moment(data.date).format('YYYY-MM-DD')}`
          defaultNotifications.created("Systemmeddelanden", notificationMessage)
        } else {
          defaultNotifications.updated("Systemmeddelanden")
        }
        history.push(navigateToSystemMessages)
      } else {
        defaultNotifications.error("Systemmeddelanden", response.statusText)
      }

    } catch (error) {
      console.log(error)
    }

    setSaving(false)
  }

  const handleInputChange = (name, value) => {
    setData({...data, [name]: value})
  }

  async function deleteMessage() {
    setSaving(true)

    try {
      const response = await sendData('/api/system/messages/delete', data, "POST")

      if(response.ok) {
        const notificationMessage = `${findName(applications, data.application)}: ${Moment(data.date).format('YYYY-MM-DD')}`
        defaultNotifications.deleted("Systemmeddelanden", notificationMessage)
        history.push(navigateToSystemMessages)
      } else {
        defaultNotifications.error("Systemmeddelanden", response.statusText)
      }
    } catch(error) {
      console.log(error)
    }

    setSaving(false)
  }

  function findName(data, id) {
    let found = data.find((element) => element.id === id.toString())
    if (found) {
      return found.name
    }
  }

  return (
    <ContentContainer
      title={mode === Mode.Create ? 'Lägg till systemmeddelande' : 'Ändra systemmeddelande'}
      fetchingData={fetching}
      backTo={navigateToSystemMessages}
      backText="Systemmeddelanden"
    >
      <LoadingContainer loading={fetching}>
        <form
          className="form"
          id="systemmessageform"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Dropdown
            name='application'
            label='Tjänst'
            placeholder={data.application ? findName(applications, data.application) : 'Välj typ av tjänst'}
            listItems={applications}
            setSelected={(e, selected) => {
              e.preventDefault()
              handleInputChange("application", parseInt(selected.id))
              setValidationErrors(prev => ({...prev, application: '', }))
            }}
            error={validationErrors.application ? true : false}
          />
          {validationErrors.application && <span className={css.validationError}>{validationErrors.application}</span>}

          <DatePicker
            label="Datum"
            name="date"
            placeholderText="Välj datum"
            dateFormat="yyyy-MM-dd"
            selectedDate={data.date ? data.date : Moment(new Date()).format('YYYY-MM-DD')}
            onChange={(date) => {
              handleInputChange("date", date ? Moment(date).format('YYYY-MM-DD') : "")
              setValidationErrors(prev => ({...prev, date: ''}))
            }}
            minDate={new Date()}
          />
          {validationErrors.date && <span className={css.validationError}>{validationErrors.date}</span>}

          <div className={css.timePickerWrapper}>
            <div>
              <TimePicker
                label="Från"
                value={data.startTime ?? ""}
                name="startTime"
                disableClock={true}
                locale="sv"
                onChange={(time) => {handleInputChange("startTime", time); setValidationErrors(prev => ({...prev, startTime: '', timeInput: ''}))}} 
                error={validationErrors.startTime || validationErrors.timeInput}
              />
              {validationErrors.startTime && <span className={css.validationError}>{validationErrors.startTime}</span>}
            </div>
            <div>
              <TimePicker 
                label="Till"
                value={data.endTime ?? ""}
                name="endTime"
                disableClock={true}
                locale="sv"
                onChange={(time) => {handleInputChange("endTime", time); setValidationErrors(prev => ({...prev, endTime: '', timeInput: ''}))}}
                error={validationErrors.endTime || validationErrors.timeInput}
              />
              {validationErrors.endTime && <span className={css.validationError}>{validationErrors.endTime}</span>}
            </div>
          </div>
          {validationErrors.timeInput && <span className={css.validationError}>{validationErrors.timeInput}</span>}

          <Textbox
            label={Placeholder.Title}
            placeholder="Ange rubrik"
            id="title"
            name="title"
            value={data?.title ?? undefined}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            validationRegister={register}
            validation={{
              required: true,
            }}
            errors={errors}
          />

          <Textbox
            label={Placeholder.Message}
            placeholder="Ange meddelande"
            id="message"
            name="message"
            value={data?.message ?? undefined}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            validationRegister={register}
            validation={{
              required: true,
            }}
            errors={errors}
          />

          <FormActions
            form="systemmessageform"
            onCancel={() => history.push(navigateToSystemMessages)}
            saving={saving}
            showDelete={mode === Mode.Edit}
            onDelete={deleteMessage}
          />
        </form>
      </LoadingContainer>
    </ContentContainer>
  )
  
}


export default SystemMessage

